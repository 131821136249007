/*styles for jquery ui daterangepicker plugin */

.ui-daterangepickercontain {
	position: absolute;
	z-index: 9999;

	.ui-daterangepicker {
		float: left;
		padding: 5px !important;
		width: auto;
		display: inline;
		background-image: none !important;
		clear: left;
	}
}

.ui-daterangepicker {
	ul, .ranges, .range-start, .range-end {
		float: left;
		padding: 0;
		margin: 0;
	}

	.ranges {
		width: auto;
		position: relative;
		padding: 5px 5px 40px 0;
		margin-left: 10px;
	}

	button.btnDone {
		float: right;
		clear: both;
		cursor: pointer;
		font-size: 1.1em;
		margin-top: 10px;
		background: none;
		border: 0 none;
	}

	ul {
		width: 17.6em;
		background: none;
		border: 0;
	}

	li {
		list-style: none;
		padding: 1px;
		cursor: pointer;
		margin: 1px 0;
		display: block;

		&.ui-state-hover, &.ui-state-active {
			padding: 0;
		}

		&.preset_0 {
			margin-top: 1.5em !important;
		}
	}

	.ui-widget-content a {
		text-decoration: none !important;
	}

	li {
		a {
			font-weight: normal;
			margin: .3em .5em;
			display: block;
		}

		span {
			float: right;
			margin: .3em .2em;
		}
	}

	.title-start, .title-end {
		display: block;
		font-size: 1em;
		margin-bottom: 10px;
	}

	.ui-datepicker-inline {
		font-size: 1em;
	}
}

.ui-daterangepicker-arrows {
	padding: 2px;
	width: 204px;
	position: relative;

	input.ui-rangepicker-input {
		width: 158px;
		margin: 0 2px 0 20px;
		padding: 2px;
		height: 1.1em;
	}

	.ui-daterangepicker-prev, .ui-daterangepicker-next {
		position: absolute;
		top: 2px;
		padding: 1px;
	}

	.ui-daterangepicker-prev {
		left: 2px;
	}

	.ui-daterangepicker-next {
		right: 2px;
	}

	.ui-daterangepicker-prev:hover, .ui-daterangepicker-next:hover, .ui-daterangepicker-prev:focus, .ui-daterangepicker-next:focus {
		padding: 0;
	}
}

/**** override for new calendar ****/

div {
	&.ui-daterangepickercontain div.ranges {
		background: none repeat scroll 0 0 #E7E7E7;
		border: 1px solid #D6D6D6;
	}

	&.ranges.ui-widget-header.ui-corner-all.ui-helper-clearfix {
		padding: 10px;
	}
}

ul.calendar-label-short + div div.range-end.hasDatepicker {
	margin-left: 10px;
}

div.ui-daterangepickercontain div.ui-daterangepicker ul.ui-widget-content {
	&.calendar-label-short {
		width: 132px !important;
		-webkit-transition: width 1s ease-in-out;
		-moz-transition: width 1s ease-in-out;
		-o-transition: width 1s ease-in-out;
		-ms-transition: width 1s ease-in-out;
		transition: width 1s ease-in-out;
	}

	width: 238px;
	-webkit-transition: width 1s ease-in-out;
	-moz-transition: width 1s ease-in-out;
	-o-transition: width 1s ease-in-out;
	-ms-transition: width 1s ease-in-out;
	transition: width 1s ease-in-out;
}

.larger-modal {
	min-width: 837px !important;
	margin-right: 20px;
	margin-bottom: 20px;
}

.larger-container {
	width: 944px;
}